<template>
  <div class="home">
    <b-container>
      <b-row class="vh-100 text-center" align-v="center">
        <b-col>
          <transition name="slide-fade">
            <b-container class="login-box">
              <div class="wrapper">
                <div class="image">
                  <img src="../assets/circular.svg" alt="circular" />
                </div>
                <div class="text-content">
                  <span class="tagline ml-3">{{ $t("home.welcome") }}</span>
                  <img src="../assets/eos.png" alt="circular" />

                  <p class="lead mt-3 ml-2">{{ $t("home.description") }}</p>
                </div>
              </div>
              <hr class="my-4" />

              <div class="button-holder">
                <router-link to="/login" >
                  <b-button size="lg" variant="primary"
                    ><i class="fas fa-sign-in-alt"></i>
                    {{ $t("home.login") }}</b-button
                  >
                </router-link>
                <router-link to="/register" >
                  <b-button size="lg" variant="secondary">
                    <i class="fas fa-user-plus"></i>
                    {{ $t("home.register") }}</b-button
                  >
                </router-link>
              </div>
            </b-container>
          </transition>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  components: {  },
};
</script>

<style lang="scss">
@import "../theme.scss";
.home::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url("~@/assets/photovoltaic-bg.jpg");
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
// .login {
//   // background: url("/waves-bg.png");
//   background: url("/photovoltaic-bg.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
//   filter: blur(4px);
// }
.login-logo {
  max-height: 20vh;
}
.login-box {
  max-width: 75vw;
  background: $white;
  padding: 1rem;
  border-radius: 1rem;
}
.user-input {
  // font-size: 2rem;
  text-align: center;
  max-width: 75%;
  margin: auto;
}
.login-button {
  padding: 1rem 5rem;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}



.wrapper {
    display: flex;
    flex-wrap: wrap;
    .image {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
    .text-content {
      margin-left: 1rem;
      flex-grow: 2;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      *{
        margin-bottom: 0.5rem;
      }

      img{
        height: 70px;
      }

      .tagline {
        font-family: 'leadfont';
        text-transform: uppercase;
      }
    }
    @include media-breakpoint-down(sm){
      .text-content{
        align-items: center;
      }
    }

    
  }
  .button-holder {
    display: flex;
    justify-content: center;
    a {
      margin: 0.5rem;
    }
  }
@include media-breakpoint-down(sm) {
  .button-holder {
    flex-wrap: wrap;
    
    a{
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
}
</style>